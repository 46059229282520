'use client';

import { SessionProvider } from 'next-auth/react';

/**
 * NextAuthのSessionProviderをラップする
 */
const NextAuthSessionProvider: ComponentWithChildren = ({ children }) => (
    <SessionProvider>{children}</SessionProvider>
);

export default NextAuthSessionProvider;
