'use client';

import { createContext } from 'react';

export type HumbergerMenuContextValue = {
    /** ハンバーガーメニューの開閉状態 */
    open: boolean | null;
    /** ハンバーガーメニューを閉じる関数 */
    handleClose: (() => void) | null;
    /** ハンバーガーメニューを開く関数 */
    handleOpen: (() => void) | null;
    /** ハンバーガーメニューの開閉状態を切り替える関数 */
    handleToggle: (() => void) | null;
};

/**
 * ハンバーガーメニューの開閉状態を管理するコンテキスト
 */
export const HumbergerMenuContext = createContext<HumbergerMenuContextValue>({
    open: null,
    handleClose: null,
    handleOpen: null,
    handleToggle: null,
});
