'use client';

import { signIn, useSession } from 'next-auth/react';
import { FullPageFallback } from '@/components/ui/Layout/FullPageFallback/FullPageFallback';
import { LoginPage } from '@/components/ui/Page/LoginPage/LoginPage';

/**
 * 認証が終わるまではローディング画面・ログイン画面を表示する
 * 必ずNextAuthSessionProviderよりも下位に配置する
 */
const AuthProvider: ComponentWithChildren = ({ children }) => {
    const { data: session, status } = useSession();

    if (status === 'loading') {
        return <FullPageFallback />;
    }

    if (!session) {
        return <LoginPage onGoogleLogin={() => signIn('google')} />;
    }

    return <>{children}</>;
};

export default AuthProvider;
