'use client';
import { createContext } from 'react';

import type { BreakPointName } from '@/domains/style/models';

export type BreakPointContextValue = {
    breakpoint: BreakPointName;
    isMobile: boolean;
    isPc: boolean;
};

/**
 * ブレークポイントを監視するためのコンテキスト
 */
export const BreakpointContext = createContext<BreakPointContextValue>({
    breakpoint: 'xs',
    isMobile: true,
    isPc: false,
});
