'use client';

import { useCallback, useState } from 'react';
import { HumbergerMenuContext } from './HumbergerMenuContext';

/**
 * ハンバーガーメニューとその開閉状態を管理するProvider
 */
export const HumbergerMenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const handleClose = useCallback(() => setOpen(false), []);
    const handleOpen = useCallback(() => setOpen(true), []);
    const handleToggle = useCallback(() => setOpen((prev) => !prev), []);

    return (
        <HumbergerMenuContext.Provider
            value={{
                open,
                handleClose,
                handleOpen,
                handleToggle,
            }}
        >
            {children}
        </HumbergerMenuContext.Provider>
    );
};
